import styles from "./Footer.module.scss";
import { AiOutlinePhone } from "react-icons/ai";
import { BiEnvelope, BiTime } from "react-icons/bi";
import { FiFacebook, FiTwitter, FiMessageSquare } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import logoImage from "../../Assets/Logo/Exchange-corner.png";

function Footer() {
	return (
		<div className={styles.footer}>
			{/* //////////////////////////////////// First block //////////////////////////////////// */}
			<div className={styles.footer__one}>
				<div className={styles.logo}>
					<img src={logoImage} alt="Dollar buy sell Corner" />
				</div>
				<div className={styles.item}>
					<div className={styles.itemIcon}>
						<AiOutlinePhone />
					</div>
					<div className={styles.itemText}>
						<a
							href="https://wa.me/8801729050990"
							target="_blank"
							rel="noreferrer"
						>
							01729050990
						</a>
					</div>
				</div>
				<div className={styles.item}>
					<div className={styles.itemIcon}>
						<BiEnvelope />
					</div>
					<div className={styles.itemText}>
						<a
							href="mailto:support@dollarbuysellbdcorner.com"
							target="_blank"
							rel="noreferrer"
						>
							support@dollarbuysellbdcorner.com
						</a>
					</div>
				</div>
				<div className={styles.item}>
					<div className={styles.itemIcon}>
						<BiTime />
					</div>
					<div className={styles.itemText}>Working Time: 10:00AM - 11:00AM</div>
				</div>
			</div>
			{/* //////////////////////////////////// Second block //////////////////////////////////// */}
			<div className={styles.footer__two}>
				<h2>Useful Links</h2>

				<div className={styles.links}>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/"
					>
						Exchange
					</NavLink>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/rates"
					>
						Rates
					</NavLink>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/about"
					>
						About us
					</NavLink>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/contacts"
					>
						Contact
					</NavLink>
				</div>
			</div>

			{/* //////////////////////////////////// Third block //////////////////////////////////// */}
			<div className={styles.footer__three}>
				<h2>Help & Support</h2>

				<div className={styles.links}>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/help"
					>
						Help
					</NavLink>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/terms-and-condition"
					>
						Terms & Conditions
					</NavLink>
					<NavLink
						className={(navData) => (navData.isActive ? styles.activeLink : ``)}
						to="/privacy-and-policy"
					>
						Privacy & Policy
					</NavLink>
				</div>
			</div>

			{/* //////////////////////////////////// Fourth block //////////////////////////////////// */}
			<div className={styles.footer__four}>
				<h2>Follow Us</h2>

				<div className={styles.socialLinks}>
					<a href="" target="_blank" className={styles.link}>
						<FiFacebook />
					</a>
					<Link to="/" className={styles.link}>
						<FiTwitter />
					</Link>
					<a href="" className={styles.link}>
						<FiMessageSquare />
					</a>
				</div>

				<p>© 2024 | All rights reserved.</p>
			</div>
		</div>
	);
}

export default Footer;
