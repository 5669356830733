import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import { FiMessageSquare } from "react-icons/fi";
import ReactGA from "react-ga";
import "./styles/App.scss";
// Actions
import { checAuthenticated, getUserInfo } from "./redux/user/userAction";
// Pages - viewer
import Home from "./pages/viewer/Home/Home";
import Exchange from "./pages/viewer/Exchange/Exchange";
import Exchange2 from "./pages/viewer/Exchange2/Exchange2";
import Rates from "./pages/viewer/Rates/Rates";
import Signin from "./pages/viewer/Signin/Signin";
import Signup from "./pages/viewer/Signup/Signup";
import Contact from "./pages/viewer/Contact/Contact";
import Profile from "./pages/viewer/Profile/Profile";
import Transaction from "./pages/viewer/Transaction/Transaction";
import Privacy from "./pages/viewer/Privacy/Privacy";
import Terms from "./pages/viewer/Terms/Terms";
import Help from "./pages/viewer/Help/Help";
// Pages - admin
import Daashboard from "./pages/admin/Daashboard/Daashboard";
import Users from "./pages/admin/Users/Users";
// Pages - global
import PageNotFound from "./pages/Global/PageNotFound";
// Assets
import { IoLogoWhatsapp } from "react-icons/io";

function App() {
	const dispatch = useDispatch();
	const TRACKING_ID = "G-DRQCRBS47S"; // OUR_TRACKING_ID

	useEffect(() => {
		dispatch(checAuthenticated);
		dispatch(getUserInfo);
	}, [dispatch]);

	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		ReactGA.pageview(window.location.pathname);
		ReactGA.event({
			category: "SOCIAL_SHARES",
			action: "COPY_LINK",
			label: "INSTAGRAM",
		});
	}, []);

	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Exchange />} />
				<Route path="/transaction/:id" element={<Exchange2 />} />
				<Route path="/about" element={<Home />} />
				<Route path="/rates" element={<Rates />} />
				<Route path="/contacts" element={<Contact />} />
				<Route path="/login" element={<Signin />} />
				<Route path="/register" element={<Signup />} />
				<Route path="/transactions" element={<Transaction />} />
				<Route path="/profile" element={<Profile />} />

				<Route path="/privacy-and-policy" element={<Privacy />} />
				<Route path="/terms-and-condition" element={<Terms />} />
				<Route path="/help" element={<Help />} />

				<Route path="/admin/transactions" element={<Daashboard />} />
				<Route path="/admin/users" element={<Users />} />

				<Route path="*" element={<PageNotFound />} />
			</Routes>
			<Toaster />
			{/* MESSANGER & WHATS APP ICON */}
			<MessengerCustomerChat
				pageId="110326994968837"
				appId="1333714597035011"
			/>
			,
			<a
				href="https://wa.me/8801729050990"
				class="whatsapp_float"
				target="_blank"
				rel="noopener noreferrer"
				title="whatsapp support"
			>
				<IoLogoWhatsapp />
			</a>
			{ <a
				href="https://t.me/cornerf77"
				class="app_float"
				target="_blank"
				rel="noopener noreferrer"
				title="telegram support"
			>
				<IoLogoWhatsapp />
			</a> }
		</div>
	);
}

export default App;
