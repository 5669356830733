import styles from "./ExchangeWrapper.module.scss";

export function FormWrapper({ heading, headingText, children }) {
	return (
		<div className={styles.formWrapper}>
			<h1 title="Start Exchange" className={styles.head} >
				{heading.split(" ")[0]}{" "}
				<span>{heading.split(" ").slice(1).join(" ")}</span>
			</h1>
			<p>{headingText}</p>
			{children}
		</div>
	);
}

export function MessageWrapper({ children }) {
	return <div className={styles.msgWrapper}>{children}</div>;
}

export function ExchangeWrapper({ children }) {
	return <div className={styles.exchWrapper}>{children}</div>;
}
